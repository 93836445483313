.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2 {
  font-weight: 600;
  font-size: 20px;
}

.primaryButton {
  background-color: #262758;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  color: #f5f7fc;
  font-weight: 600;
}

.primaryButton:disabled {
  background-color: #d1d6e5;
  cursor: not-allowed;
}

.secondaryButton {
  border: none;
  background: none;
  font-size: 16px;
  color: #262758;
  font-weight: 600;
}

.secondaryButton:disabled {
  color: #8990a3;
  cursor: not-allowed;
}

.tertiaryButton {
  background: #ecf4f9;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  color: #262758;
}
.requiredStar {
  color: #cc0000;
}
.tertiaryButton:disabled {
  background-color: #e6e9f0;
  color: #8990a3;
  cursor: not-allowed;
}

.a_deleteButton {
  background: #cc0000;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  color: #f5f7fc;
}

.form-check-input,
.form-check-input[type='checkbox'] {
  border: 1px solid #cad9f3;
  box-shadow: none;
  margin-top: 0;
}

.form-check-input:checked {
  background-color: #262758;
  border: none;
}

.modal-backdrop.show {
  opacity: 1;
  background-color: #26275899;
}

.modal-content {
  box-shadow: 0px 4px 16px 0px #26275833;
  border-radius: 0;
}

@media screen and (min-width: 576px) {
  h2 {
    font-size: 24px;
  }

  .form-check-input,
  .form-check-input[type='checkbox'] {
    border: 2px solid #cad9f3;
  }

  .modal-content {
    border-radius: 16px;
    padding: 32px 48px;
  }
}

@media screen and (min-width: 1200px) {
  h2 {
    font-size: 28px;
  }
}

@media screen and (min-width: 2800px) {
  h2 {
    font-size: 56px;
  }
  .form-check-input,
  .form-check-input[type='checkbox'] {
    border: 3px solid #cad9f3;
    border-radius: 13px;
  }
}
