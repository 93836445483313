@font-face {
  font-family: 'NoirNo1';
  font-weight: 700;
  src: local('NoirNo1'), url('./fonts/NoirNo1-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'NoirNo1';
  font-weight: 600;
  src: local('NoirNo1'), url('./fonts/NoirNo1-Demibold.otf') format('opentype');
}

@font-face {
  font-family: 'NoirNo1';
  font-style: italic;
  src: local('NoirNo1'), url('./fonts/NoirNo1-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'NoirNo1';
  src: local('NoirNo1'), url('./fonts/NoirNo1.otf') format('opentype');
  font-weight: normal;
}

body {
  margin: 0;
  font-family: NoirNo1, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #262758;
  min-height: 100vh;
}

@media (min-width: 576px) {
  body {
    background: #fafafa;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
